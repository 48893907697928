import React from 'react';
import { Outlet } from 'react-router-dom';
import useMatchedRoute from '../Utils/Routes/useMatchedRoute';
import { Pipeline, Pipe } from 'smart-react';
import RoutesItems from '../Routes/RoutesItems';
/**
 * Base Layout to attach context prover
 * @param {React.Component} Component
 * @returns {React.Component} Component
 */
const BaseLayoutHOC = (Component) => (props) => {
  const route = useMatchedRoute(RoutesItems); // Return active route object

  const provider = []; // contain context proivders

  /**
   * Wrap context provider on specified Routes
   */
  if (route?.context) {
    const contextLength = route?.context?.length;
    route?.context.map((ct, i) => {
      const ContextProvider = ct;
      provider[i] = <ContextProvider children={<Pipe />} />;
    });
    provider[contextLength] = <>{<Outlet />} </>;
  }
  const children =
    provider.length > 0 ? <Pipeline components={provider} /> : <Outlet />;
  return <Component title={route?.page_title} children={children} {...props} />;
};

export default BaseLayoutHOC;
