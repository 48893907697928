import { dexieCore } from '../../Utils/Storage/IndexDB/dexieDb';
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  TokenExpireMessage,
} from '../../constants/applicationConstants';
/**
 * Default Configurations of API
 */
const defaultConfig = {
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: '*/*',
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  // body data type must match "Content-Type" header
};

/**
 * Used to Handle Error Response, axios error and all other type of errors
 * @param {*} error api error
 */
const handleApiError = (error) => {
  let errorResponse;

  if (error.response && error.response.data) {
    // handle error responses in valid format
    // JSON stringify if you need the json and use it later
    errorResponse = JSON.stringify(error.response.data);
  } else if (error.request) {
    // TO Handle the default error response for Network failure or 404 etc.,
    errorResponse =
      error.request.message || error.request.statusText || error.message;
  } else {
    errorResponse = error.message;
  }
  // Check if there is aborted in error message then it will not throw the exception
  if (!errorResponse.match('aborted')) {
    throw new Error(errorResponse);
  }
};
/**
 * Create fetch API Request
 * @param {*} requestConfig api request configs
 * @returns {json} returns API json response
 */
export const apiRequest = async (requestConfig, isServiceWorker = false) =>
  // Default options are marked with *
  {
    let isRequestPending = true;
    // Create an AbortController
    const controller = new AbortController();
    // Get the AbortSignal from the controller
    const { signal } = controller;
    const config = { ...defaultConfig, ...requestConfig };
    const { baseURL, ...otherConfig } = config;
    otherConfig.singal = signal;
    if (!isServiceWorker) {
      window.addEventListener('beforeunload', (e) => {
        if (isRequestPending) {
          controller.abort();
        }
        return null;
      });
    }

    try {
      const res = await fetch(baseURL, otherConfig);
      const dt = res.json();
      isRequestPending = false;
      return dt;
    } catch (error) {
      isRequestPending = false;
      handleApiError(error);
      return null;
    }
  };

/**
 * Create Request Object for Graphql API's
 * @param {*} baseURL
 * @param {*} query
 * @param {*} signal
 * @param {*} headers
 * @returns {json} returns API json response
 */
export const graphqlRequest = async ({
  query,
  signal,
  headers,
  isServiceWorker,
}) => {
  if (isServiceWorker) {
    const idToken = await dexieCore.Configurations.get(ID_TOKEN);
    headers.Authorization = idToken.value;
  }
  const request = {
    baseURL: process.env.GRAPHQL_URL,
    method: 'POST',
    signal,
    headers: {
      ...defaultConfig.headers,
      ...headers,
      ...{ AuthType: 'Graphql' },
    },

    body: JSON.stringify({
      query,
    }),
  };
  const data = await apiRequest(request, isServiceWorker);
  if (data?.errors?.length > 0) {
    if (TokenExpireMessage.includes(data?.errors[0]?.message)) {
      sessionStorage.clear();
      window.location.href = '/?token=expired';
    }
  }

  return data;
};

export const apiCall = async ({
  url,
  signal,
  body,
  method,
  headers: initialHeaders = {},
  isServiceWorker,
  accessToken,
}) => {
  let headers;

  if (isServiceWorker) {
    const accessTokenTemp = await dexieCore.Configurations.get(ACCESS_TOKEN);
    headers = {
      ...initialHeaders,
      Authorization: `Bearer ${accessTokenTemp.value}`,
      'X-Application-Id': process.env.APP_ID,
    };
  } else {
    headers = {
      'X-Application-Id': process.env.APP_ID,
      Authorization: `Bearer ${accessToken}`,
      ...initialHeaders,
    };
  }

  const request = {
    baseURL: `${process.env.BASE_URL}${url}`,
    method,
    signal,
    headers: {
      ...defaultConfig.headers,
      ...headers,
      AuthType: 'OAuth2',
    },
    body: JSON.stringify(body),
  };

  const data = await apiRequest(request, isServiceWorker);

  if (data?.errors?.length > 0) {
    if (TokenExpireMessage.includes(data?.errors[0]?.message)) {
      sessionStorage.clear();
      window.location.href = '/?token=expired';
    }
  }

  if (TokenExpireMessage.includes(data?.Message)) {
    sessionStorage.clear();
    window.location.href = '/?token=expired';
  }

  return data;
};
